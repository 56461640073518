const ultimateHosting = {
  1: {
    title: 'Ultimate Plan 1',
    discountPercent: 50,
    priceINR: 300,
    priceUSD: 4.02,
    featureLabel: 'Most Popular',
    featured: false,
    features: [
      'Unlimited Domain',
      '25GB SSD Disk Space',
      'Unlimited Bandwidth',
      'Unlimited Email Accounts',
      'Unlimited MYSQL Databases',
      'Free SSL Certificate',
      'Unlimited Sub-Domain'
    ],
    buttonLabel: 'Buy Now',
    buttonURL: 'https://my.bluegeekhosting.com/cart.php?a=add&pid=7'
  },
  2: {
    title: 'Ultimate Plan 2',
    discountPercent: 50,
    priceINR: 500,
    priceUSD: 6.7,
    featured: true,
    featureLabel: 'Most Popular',
    features: [
      'Unlimited Domain',
      '45GB SSD Disk Space',
      'Unlimited Bandwidth',
      'Unlimited Email Accounts',
      'Unlimited MYSQL Databases',
      'Free SSL Certificate',
      'Unlimited Sub-Domain'
    ],
    buttonLabel: 'Buy Now',
    buttonURL: '/contact-us'
  }
}

export default ultimateHosting
